// Google events
//
// Registration events
export const REGISTRATION = 'registration';
export const CREATE_ACCOUNT = 'submit_account_info';
export const VERIFY_AUTH_CODE = 'submit_verify_recovery_method';
export const CREATE_PROFILE = 'submit_profile';

// Status
export const ATTEMPT = 'attempt';
export const SUCCESS = 'success';
export const FAILED = 'failed';
