import { useEffect, useState } from 'react';
import getPatientAffiliationsAsync from '../api/patients/getPatientAffiliationsAsync';
import { PatientAffiliation } from '../interfaces/register';

const usePatientAffiliations = (userIds: number[] | null) => {
  const [affiliations, setAffiliations] = useState<PatientAffiliation[] | null>(
    null
  );

  const setPatientAffiliations = async () => {
    if (userIds !== null) {
      const {
        affiliations: patientAffiliations,
      } = await getPatientAffiliationsAsync({ userIds });
      setAffiliations(patientAffiliations);
    } else {
      setAffiliations(null);
    }
  };

  useEffect(() => {
    setPatientAffiliations();
  }, [userIds]);

  return affiliations;
};

export default usePatientAffiliations;
