/* eslint @typescript-eslint/camelcase: 0 */
import snakeCaseKeys from 'snakecase-keys';
import { mobilePageView, mobileEvent } from './mobile';

export type CategoryType = 'registration';

interface Event {
  action: string;
  category: CategoryType;
  label?: string;
  value?: string;
  parameters?: Record<string, any>;
}

// For GTM
export const GTM_TRACKING_ID = 'GTM-TSLRT8';

const isDev = process.env.NODE_ENV === 'development';

export const pageview = (url) => {
  const path = url.replace(/\?.*$/g, ''); // strip query params

  if (isDev) {
    // console.log('pageview', path);
    return;
  }

  if (typeof window !== 'undefined') {
    mobilePageView(path);
  }
};

export const event = ({
  action,
  category,
  label,
  value,
  parameters,
}: Event) => {
  if (isDev) {
    // console.log('event', { action, category, label, value });
    return;
  }

  if (typeof window !== 'undefined') {
    let params: Record<string, any> | undefined =
      typeof parameters !== 'undefined' ? parameters : undefined;
    if (typeof label !== 'undefined' && typeof value !== 'undefined') {
      params = { [label]: value, ...params };
    }
    if (typeof params !== 'undefined') {
      params = snakeCaseKeys(params);
    }

    mobileEvent(category, action, params);
  }
};
