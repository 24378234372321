/* eslint-disable no-console */
import { ErrorInfo } from 'react';
import * as Sentry from '@sentry/node';

const logError = (error: Error, errorInfo: ErrorInfo | Record<string, any>) => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  } else {
    console.error(error);
    Object.keys(errorInfo).forEach(key => {
      console.error(key, errorInfo[key]);
    });
  }
};

export default logError;
