import { useRouter } from 'next/router';

const useRegistrationRouter = () => {
  const router = useRouter();
  const { query, locale, push: nextPush, ...rest } = router;
  const { affiliation } = query;

  const push = (url: string) =>
    affiliation
      ? nextPush(`/${locale}/${affiliation}/register/${url.replace(/^\//, '')}`)
      : nextPush(`/${locale}/${url.replace(/^\//, '')}`);

  const registrationRouter = {
    push,
    query,
    locale,
    ...rest,
  };

  return registrationRouter;
};

export default useRegistrationRouter;
