import apiFetch from '../core/apiFetch';
import { getAuthTokenAsync } from '../../utils/authToken';
import { checkStatus, handleError } from '../../utils/api';
import { PatientAffiliation } from '../../interfaces/register';

interface PatientAffiliationQuery {
  email: string;
  birthdate: string;
  userIds: number[];
}

const getPatientAffiliationsAsync = async ({
  email,
  birthdate,
  userIds,
}: Partial<PatientAffiliationQuery>): Promise<{
  affiliations: PatientAffiliation[];
}> => {
  const baseUrl = 'api/v2/patients/affiliations';
  const url =
    userIds && userIds.length > 0
      ? userIds.reduce(
          (acc, userId) => `${acc}user_ids[]=${userId}&`,
          `${baseUrl}?`
        )
      : `${baseUrl}?email=${email}&birthdate=${birthdate}`;

  try {
    const authToken = await getAuthTokenAsync();

    const response = await apiFetch(authToken, url, {
      method: 'GET',
    });

    await checkStatus(response);

    const { affiliations } = await response.json();

    return { affiliations };
  } catch (error) {
    handleError('unexpected status - get v2 patient affiliations', error);
    return { affiliations: [] };
  }
};

export default getPatientAffiliationsAsync;
