import React, { Component, ReactNode, ErrorInfo } from 'react';
import logError from '../utils/errors/logError';

interface Props {
  children?: ReactNode;
}

interface State {
  error?: null | Error;
  errorInfo?: null | ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Log in reporting service
    logError(error, errorInfo);

    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { errorInfo, error } = this.state;

    if (errorInfo && process.env.NODE_ENV === 'development') {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details>
            {
              // @ts-ignore
              error && error.toString()
            }
            <br />
            {
              // @ts-ignore
              errorInfo && errorInfo.componentStack
            }
          </details>
        </div>
      );
    }

    if (errorInfo) {
      return <h2>Something went wrong.</h2>;
    }

    return children;
  }
}

export default ErrorBoundary;
