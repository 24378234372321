import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { CustomParams, getCustomParams } from '../../utils/customParams';

interface PersistParams {
  params: CustomParams | undefined;
}

interface PersistParamsState {
  params: CustomParams | undefined;
}

const persistParamsContext = createContext<PersistParams>({
  params: undefined,
});

const getPersistedParams = () => {
  const sessionDefined = typeof window !== 'undefined' && window.sessionStorage;
  return sessionDefined ? sessionStorage.getItem('PERSISTED_PARAMS') : null;
};

export function usePersistParamsProvider(): PersistParams {
  const router = useRouter();

  const [state, setState] = useState<PersistParamsState>({
    params: JSON.parse(getPersistedParams() || '{}'),
  });

  useEffect(() => {
    let params: CustomParams = {};

    const sessionParams = getPersistedParams();

    if (sessionParams && sessionParams !== '{}') {
      params = JSON.parse(sessionParams);
    } else {
      params = getCustomParams();
    }

    sessionStorage.setItem('PERSISTED_PARAMS', JSON.stringify(params));

    setState({ ...state, params });
  }, [router.pathname]);

  return { ...state };
}

export function PersistParamsProvider({ children = null }: any) {
  const persistParams: PersistParams = usePersistParamsProvider();
  return (
    <persistParamsContext.Provider value={persistParams}>
      {children}
    </persistParamsContext.Provider>
  );
}

export function usePersistParams(): PersistParams {
  return useContext(persistParamsContext);
}
