import fetch from 'isomorphic-unfetch';
import { RequestOptions } from '../../interfaces/api';

const nextFetch = (url: string, options: RequestOptions) => {
  const { headers, ...rest } = options;
  const baseUrl =
    typeof window === 'undefined' ? `${process.env.NEXT_URL}` : '';
  const urlExt = url.startsWith('/') ? url : `/${url}`;

  return fetch(`${baseUrl}${urlExt}`, {
    ...rest,
    headers: {
      ...headers,
    },
  });
};

export default nextFetch;
