import { isFunction, get } from 'lodash';
import { REGISTRATION } from '../constants/events';
import { CategoryType } from './analytics';

type Window = typeof window & {
  webkit?: any;
  Android?: any;
};

const isDev = process.env.NODE_ENV === 'development';

export const mobilePageView = (path: string) => {
  if (typeof window === 'undefined' || isDev) {
    return;
  }

  const isAndroid = typeof (window as Window).Android !== 'undefined';
  const isIOS = typeof (window as Window).webkit !== 'undefined';
  const isMobile = isAndroid || isIOS;

  if (!isMobile) {
    return;
  }
  const body = { event_name: 'pageview', parameters: { path } };

  if (isAndroid) {
    (window as Window).Android.pageviewEvent(JSON.stringify(body));
  }
  if (
    isIOS &&
    typeof (window as Window).webkit.messageHandlers?.pageviewEvent
      ?.postMessage !== 'undefined'
  ) {
    (window as Window).webkit.messageHandlers.pageviewEvent.postMessage(
      JSON.stringify(body)
    );
  }
};

export const mobileEvent = (
  category: CategoryType,
  eventName: string,
  parameters?: Record<string, any>
) => {
  if (typeof window === 'undefined' || isDev) {
    return;
  }

  const isAndroid = typeof (window as Window).Android !== 'undefined';
  const isIOS = typeof (window as Window).webkit !== 'undefined';
  const isMobile = isAndroid || isIOS;

  if (!isMobile) {
    return;
  }

  let body: { event_name: string; parameters?: Record<string, any> } = {
    event_name: eventName,
  };
  if (typeof parameters !== 'undefined') {
    body = { ...body, parameters };
  }

  if (category === REGISTRATION) {
    if (isAndroid) {
      (window as Window).Android.registrationEvent(JSON.stringify(body));
    }
    if (
      isIOS &&
      typeof (window as Window).webkit.messageHandlers?.registrationEvent
        ?.postMessage !== 'undefined'
    ) {
      (window as Window).webkit.messageHandlers.registrationEvent.postMessage(
        JSON.stringify(body)
      );
    }
  }
};

export const dispatchMobileRegistrationSuccess = (
  { token, userId }: { token?: string; userId?: number },
  method: 'signin' | 'registration'
) => {
  let mobileDetected = false;
  const body = {
    access_token: token,
    user_id: userId,
    method,
  };
  if (
    typeof (window as Window).Android !== 'undefined' &&
    isFunction((window as Window).Android.mobileRegistrationSuccess)
  ) {
    /* eslint @typescript-eslint/camelcase: 0 */
    (window as Window).Android.mobileRegistrationSuccess(JSON.stringify(body));
    mobileDetected = true;
  }

  if (
    isFunction(
      get(
        window,
        'webkit.messageHandlers.mobileRegistrationSuccess.postMessage',
        null
      )
    )
  ) {
    /* eslint @typescript-eslint/camelcase: 0 */
    (window as Window).webkit.messageHandlers.mobileRegistrationSuccess.postMessage(
      JSON.stringify(body)
    );
    mobileDetected = true;
  }

  return mobileDetected;
};

export const dispatchSignInRequested = () => {
  // we have to reuse the same method called in registration but with diff params
  // since mobile is not recognizing dispatching to `signinRequested`
  return dispatchMobileRegistrationSuccess({}, 'signin');
};

export const getMobileActivitySource = (): string | null => {
  let activitySource: string | null = null;
  if (
    typeof window === 'undefined' &&
    typeof (window as Window).Android === 'undefined' &&
    typeof (window as Window).webkit === 'undefined'
  ) {
    return null;
  }

  if (typeof (window as Window).Android !== 'undefined') {
    activitySource = 'mobile-android-mdlive';
  }
  if (typeof (window as Window).webkit !== 'undefined') {
    activitySource = 'mobile-ios-mdlive';
  }

  return activitySource;
};
