import { checkStatus, handleError } from './api';
import nextFetch from '../api/core/nextFetch';

/* authToken - generic auth for validating username and creating simple patients */

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)) // eslint-disable-line prefer-template
      .join('')
  );

  return JSON.parse(jsonPayload);
};

const getAuthToken = () => {
  const item = localStorage.getItem(
    `authToken_${process.env.NEXT_ENVIRONMENT}`
  );
  if (!item) {
    return '';
  }

  const { value, expires } = JSON.parse(item);

  const now = new Date().getTime();

  if (now > new Date(expires).getTime() - 60 * 10 * 1000) {
    localStorage.removeItem(`authToken_${process.env.NEXT_ENVIRONMENT}`);
    return '';
  }

  return value;
};

const setAuthToken = (authToken) => {
  const { exp } = parseJwt(authToken);
  const item = {
    value: authToken,
    expires: new Date(exp * 1000),
  };

  localStorage.setItem(
    `authToken_${process.env.NEXT_ENVIRONMENT}`,
    JSON.stringify(item)
  );
};

export const getAuthTokenAsync = async (): Promise<string> => {
  if (typeof window !== 'undefined') {
    const cache = getAuthToken();

    if (cache) {
      return cache;
    }
  }

  try {
    const authResponse = await nextFetch(`/api/auth`, { method: 'GET' });

    await checkStatus(authResponse);

    const { authToken } = await authResponse.json();

    if (typeof window !== 'undefined') {
      setAuthToken(authToken);
    }

    return authToken;
  } catch (error) {
    handleError('unexpected status - get auth token', error);
    return '';
  }
};
