import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRouter } from 'next/router';

import { SIGN_IN_URL } from '../../constants/login';
import { CustomParams, getCustomParams } from '../../utils/customParams';

const INITIAL_PARAMS = {};

interface Customizable {
  isCustomizable: boolean;
  customizableService: string;
  setCustomizable: (value: boolean) => void;
  params: CustomParams | undefined;
}

interface CustomizableState {
  isCustomizable: boolean;
  customizableService: string;
  params: CustomParams | undefined;
}

const customizableContext = createContext<Customizable>({
  isCustomizable: false,
  customizableService: '',
  setCustomizable: () => null,
  params: undefined,
});

export function useCustomizableProvider(): Customizable {
  const router = useRouter();

  const isLocalStoragePresent =
    typeof window !== 'undefined' && window.localStorage;

  const [state, setState] = useState<CustomizableState>({
    isCustomizable: false,
    customizableService: '',
    params: INITIAL_PARAMS,
  });

  const isCustomizablePresent = (params: CustomParams | any): boolean => {
    return Boolean(params.login_only) && params.login_only === 'true';
  };

  useEffect(() => {
    let isCustomizable = false;
    let params: CustomParams | any = INITIAL_PARAMS;

    if (router.pathname === SIGN_IN_URL) {
      params = getCustomParams();
      isCustomizable = isCustomizablePresent(params);
    } else {
      const isCustomizableLocal: string | null = isLocalStoragePresent
        ? localStorage.getItem('SIGN_IN_CUSTOMIZABLE')
        : null;
      const paramsLocal: string | null = isLocalStoragePresent
        ? localStorage.getItem('SIGN_IN_CUSTOMIZABLE_PARAMS')
        : null;

      isCustomizable = isCustomizableLocal === 'true';
      params = JSON.parse(paramsLocal || `"${INITIAL_PARAMS}"`);
    }

    setState({
      ...state,
      params,
      isCustomizable,
    });
  }, [router.pathname]);

  useEffect(() => {
    localStorage.setItem(
      'SIGN_IN_CUSTOMIZABLE',
      JSON.stringify(state.isCustomizable)
    );
    localStorage.setItem(
      'SIGN_IN_CUSTOMIZABLE_PARAMS',
      JSON.stringify(state.params)
    );
  }, [state.isCustomizable, state.params]);

  const setCustomizable = useCallback(
    (isCustomizable: boolean) => {
      setState({ ...state, isCustomizable });
    },
    [state, setState]
  );

  return { ...state, setCustomizable };
}

export function CustomizableProvider({ children = null }: any) {
  const customizable: Customizable = useCustomizableProvider();
  return (
    <customizableContext.Provider value={customizable}>
      {children}
    </customizableContext.Provider>
  );
}

export function useCustomizable(): Customizable {
  return useContext(customizableContext);
}
