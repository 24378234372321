import React, {
  ReactElement,
  FunctionComponent,
  createContext,
  useState,
} from 'react';
import {
  Branding,
  BrandingImages,
  BrandingColors,
  BrandingAffiliation,
  BrandingOptions,
  BrandingContent,
  BrandingPaths,
  BrandingCustomSignIn,
} from '../../interfaces/branding';

interface BrandingContextType {
  images: BrandingImages;
  colors: BrandingColors;
  affiliation: BrandingAffiliation;
  options: BrandingOptions;
  paths: BrandingPaths;
  customSignIn: BrandingCustomSignIn;
}

interface BrandingProps {
  branding?: BrandingContextType;
  brandingContent?: BrandingContent;
  changeBrandingContent(BrandingContent): void;
}

export const BrandingContext = createContext<BrandingProps>({
  branding: undefined,
  brandingContent: undefined,
  changeBrandingContent: () => {},
});
export const BrandingConsumer = BrandingContext.Consumer;

interface Props {
  children: ReactElement;
  branding?: Branding;
}

export const BrandingProvider: FunctionComponent<Props> = ({
  children,
  branding,
}: Props) => {
  if (typeof branding === 'undefined') {
    return children;
  }

  const { content, ...rest } = branding;
  const [brandingContent, setBrandingContent] = useState<
    BrandingContent | undefined
  >(content);

  const changeBrandingContent = (newContent: BrandingContent) =>
    setBrandingContent(newContent);

  return (
    <BrandingContext.Provider
      value={{ branding: { ...rest }, brandingContent, changeBrandingContent }}
    >
      {children}
    </BrandingContext.Provider>
  );
};
export default BrandingProvider;
