import { getCustomParams } from '../utils/customParams';

/**
 * @description THIS HOOK SHOULD ONLY BE USED FOR ADOBE ANALYTICS PURPOSES.
 * @returns {function getUrlForComponentRoute(url:string): string | undefined}
 */
function useComponentAsRoute(): {
  getUrlForComponentRoute(url: string): string | undefined;
} {
  /**
   * @description This function is used to get the URL for a component route.
   * @example getCustomParams('/sign-in?params=some_string_value') => '/sign-in#password'
   * @param {string} url - Current url from router.
   * @returns {string | undefined} - Returns a URL for a component route.
   */
  const getUrlForComponentRoute = (url: string): string | undefined => {
    const customParams: any = getCustomParams();
    if (customParams.params) {
      if (url.includes('/sign-in')) {
        return '/sign-in#password';
      }

      return undefined;
    }

    return undefined;
  };

  return { getUrlForComponentRoute };
}

export default useComponentAsRoute;
